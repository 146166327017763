exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-arduino-cloud-tsx": () => import("./../../../src/pages/arduino-cloud.tsx" /* webpackChunkName: "component---src-pages-arduino-cloud-tsx" */),
  "component---src-pages-built-in-examples-tsx": () => import("./../../../src/pages/built-in-examples.tsx" /* webpackChunkName: "component---src-pages-built-in-examples-tsx" */),
  "component---src-pages-certifications-tsx": () => import("./../../../src/pages/certifications.tsx" /* webpackChunkName: "component---src-pages-certifications-tsx" */),
  "component---src-pages-cloud-api-tsx": () => import("./../../../src/pages/cloud-api.tsx" /* webpackChunkName: "component---src-pages-cloud-api-tsx" */),
  "component---src-pages-hardware-tsx": () => import("./../../../src/pages/hardware.tsx" /* webpackChunkName: "component---src-pages-hardware-tsx" */),
  "component---src-pages-homepage-tsx": () => import("./../../../src/pages/homepage.tsx" /* webpackChunkName: "component---src-pages-homepage-tsx" */),
  "component---src-pages-language-reference-tsx": () => import("./../../../src/pages/language-reference.tsx" /* webpackChunkName: "component---src-pages-language-reference-tsx" */),
  "component---src-pages-learn-tsx": () => import("./../../../src/pages/learn.tsx" /* webpackChunkName: "component---src-pages-learn-tsx" */),
  "component---src-pages-libraries-tsx": () => import("./../../../src/pages/libraries.tsx" /* webpackChunkName: "component---src-pages-libraries-tsx" */),
  "component---src-pages-micropython-tsx": () => import("./../../../src/pages/micropython.tsx" /* webpackChunkName: "component---src-pages-micropython-tsx" */),
  "component---src-pages-programming-tsx": () => import("./../../../src/pages/programming.tsx" /* webpackChunkName: "component---src-pages-programming-tsx" */),
  "component---src-pages-retired-tsx": () => import("./../../../src/pages/retired.tsx" /* webpackChunkName: "component---src-pages-retired-tsx" */),
  "component---src-pages-software-ide-tsx": () => import("./../../../src/pages/software/ide.tsx" /* webpackChunkName: "component---src-pages-software-ide-tsx" */),
  "component---src-pages-software-plc-ide-tsx": () => import("./../../../src/pages/software/plc-ide.tsx" /* webpackChunkName: "component---src-pages-software-plc-ide-tsx" */),
  "component---src-pages-software-tsx": () => import("./../../../src/pages/software.tsx" /* webpackChunkName: "component---src-pages-software-tsx" */),
  "component---src-pages-tutorials-tsx": () => import("./../../../src/pages/tutorials.tsx" /* webpackChunkName: "component---src-pages-tutorials-tsx" */),
  "component---src-templates-product-tsx": () => import("./../../../src/templates/product.tsx" /* webpackChunkName: "component---src-templates-product-tsx" */),
  "component---src-templates-tutorial-tsx": () => import("./../../../src/templates/tutorial.tsx" /* webpackChunkName: "component---src-templates-tutorial-tsx" */)
}

